import { render, staticRenderFns } from "./PutOrder.vue?vue&type=template&id=0ca2d954&scoped=true&"
import script from "./PutOrder.vue?vue&type=script&lang=js&"
export * from "./PutOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca2d954",
  null
  
)

export default component.exports