<template>
  <div></div>
</template>

<script>
import {post} from "@/services/api";

export default {
  name: "PutOrder",
  mounted() {
    this.$store.commit('loaderManager', true)
    let order = JSON.parse(localStorage.getItem('client--offer'));
    post('credit-order/put-order', {
      credits: order.credits,
      price: order.price
    }, true).then(() => {
      this.$swal({
        title: 'Success',
        text: 'Thanks :)',
        icon: 'success',
        showConfirmButton: false,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        timer: 1500,
      }).then(() => this.$router.push({name: 'dashboard'}));
    }).finally(() => this.$store.commit('loaderManager', false))
  }
}
</script>

<style scoped>

</style>
